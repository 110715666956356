import service from '@/utils/request.js'
// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/****************************************************************
 * 仓库管理
 * 
 * 库存查询
 ***************************************************************/


/**
 * 仓库列表查询
 */
export function get_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/warehouse/list',
      method: 'get',
      params:data
    })
}

/**
 * 编辑仓库
 */
export function update(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/warehouse/update',
      method: 'post',
      data
    })
}

/**
 * 所属部门列表
 */
export function department_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/warehouse/department_list', 
      method: 'get',
      params:data
    })
}


/**
 * 删除仓库
 */
export function deletes(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/warehouse/delete',
      method: 'get',
      params:data
    })
}



/**
 * 添加仓库
 */
export function save(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/warehouse/save',
      method: 'post',
      data
    })
}



/**
 * 库存查询
 */
export function warehouse_goods(data={}) {
  data.sign = encrypt(data);
  return service.request({
    url: '/admin/warehouse/warehouse_goods', 
    method: 'get',
    params:data
  })
}

